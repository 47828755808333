import React, {Component} from 'react';
import {Modal} from "react-responsive-modal";
import {NavLink} from "react-router-dom";
import {hasRole, logout} from "./Utils";
import ChangeBHMView from "./ChangeBHMView";

export default class MainMenu extends Component {

	constructor() {
		super();
		this.state = {
            modalOpen: false
		}
	}
	onOpenModal() {
        this.setState({
            modalOpen: true
        })
    }

    onCloseModal() {
        this.setState({
            modalOpen: false
        })
    }

	logout() {
		logout();
	}

	render() {
		return (
			<div style={{height: this.props.user !== undefined && this.props.user !== null ? "40px" : "0"}}>
				{this.props.user !== undefined && this.props.user !== null &&
				<ul className="header fixed-top">
					<div className="form-row">
						{(hasRole(this.props.user, 'CONTROLLER') || hasRole(this.props.user, 'MAIN_CONTROLLER')
							|| hasRole(this.props.user, 'MARKETING')) &&
						<div className="col-md-10">
							<div className="d-flex">
								{(hasRole(this.props.user, 'CONTROLLER') || hasRole(this.props.user, 'MAIN_CONTROLLER')) &&
								<div>
									<button type="button" className="btn btn-info dropdown-toggle"
											data-toggle="dropdown" aria-expanded="false">Shartnoma asosida
									</button>
									<div className="dropdown-menu">
										<a className="dropdown-item linkStyle" href="/agreements">Shartnomalar</a>
										<div className="dropdown-divider"/>
										<a className="dropdown-item linkStyle"
										   href="/tendencies?type=paid">Yo`nalishlar</a>
										<div className="dropdown-divider"/>
										<a className="dropdown-item linkStyle" href="/groups?type=paid">Guruhlar</a>
										<div className="dropdown-divider"/>
										<a className="dropdown-item linkStyle"
										   href="/listeners?type=paid">Tinglovchilar</a>
										<div className="dropdown-divider"/>
										<a className="dropdown-item linkStyle" href="/latePayments">Kechikib
											to`laganlar</a>
										<div className="dropdown-divider"/>
										<a className="dropdown-item linkStyle" href="/newPayments">Yangi to`laganlar</a>
									</div>
								</div>
								}

								{hasRole(this.props.user, 'MARKETING') &&
									<>
										<li><NavLink className="btn btn-info ml-1" to="/agreements">Shartnomalar</NavLink></li>
										<li><NavLink className="btn btn-info ml-1" to="/tendencies?type=paid">Yo`nalishlar</NavLink></li>
										<li><NavLink className="btn btn-info ml-1" to="/groups?type=paid">Guruhlar</NavLink></li>
										<li><NavLink className="btn btn-info ml-1" to="/listeners?type=paid">Tinglovchilar</NavLink></li>
										<li><NavLink className="btn btn-info ml-1" to="/latePayments">Kechikib to`laganlar</NavLink></li>
										<li><NavLink className="btn btn-info ml-1" to="/newPayments">Yangi to`laganlar</NavLink></li>
									</>
								}
								<div className="ml-1">
									<button type="button" className="btn btn-info dropdown-toggle" data-toggle="dropdown" aria-expanded="false">
										Taqsimot asosida
									</button>
									<div className="dropdown-menu">
										<a className="dropdown-item linkStyle" href="/tendencies?type=free">Yo`nalishlar</a>
										<div className="dropdown-divider"/>
										<a className="dropdown-item linkStyle" href="/groups?type=free">Guruhlar</a>
										<div className="dropdown-divider"/>
										<a className="dropdown-item linkStyle" href="/listeners?type=free">Tinglovchilar</a>
									</div>
								</div>
								{hasRole(this.props.user, 'MARKETING') &&
								<div className="ml-1">
									<button type="button" className="btn btn-info dropdown-toggle" data-toggle="dropdown" aria-expanded="false">
										Arxiv
									</button>
									<div className="dropdown-menu">
										<a className="dropdown-item linkStyle" href="/agreements2">Shartnomalar</a>
										<div className="dropdown-divider"/>
										<a className="dropdown-item linkStyle" href="/listeners2">Tinglovchilar</a>
									</div>
								</div>
								}
								<li><NavLink to="/deletedObjects" className="btn btn-info ml-1">O`chirilganlar</NavLink></li>
								{(hasRole(this.props.user, 'CONTROLLER') || hasRole(this.props.user, 'MAIN_CONTROLLER')) &&
								<>
									<div className="ml-1">
										<button type="button" className="btn btn-info dropdown-toggle" data-toggle="dropdown" aria-expanded="false">
											Sertifikatlar
										</button>
										<div className="dropdown-menu">
											<a className="dropdown-item linkStyle" href="/revertedCertificates">Bekor qilinganlar</a>
											<a className="dropdown-item linkStyle" href="/duplicateCertificates">Dublikatlar</a>
										</div>
									</div>
									<li><NavLink to="/creport" className="btn btn-info ml-1">Hisobotlar</NavLink></li>
									<li><NavLink to="/settings" className="btn btn-info ml-1">Sozlamalar</NavLink></li>
								</>
								}
							</div>
						</div>
						}

						{hasRole(this.props.user, 'ACCOUNTANT') &&
						<div className="col-md-10">
							<div className="d-flex">
								<div className="ml-1">
									<button type="button" className="btn btn-info dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Sozlamalar</button>
									<div className="dropdown-menu">
										<a className="dropdown-item linkStyle" href="/editRequisites">Markaz rekvizitlari</a>
										<div className="dropdown-divider"/>
										<span className="dropdown-item linkStyle" onClick={this.onOpenModal.bind(this)}>BHM</span>
									</div>
								</div>
								<div>
									<li><NavLink className="btn btn-info ml-1" to="/paidTendencies?type=paid">Yo`nalishlar</NavLink></li>
									<li><NavLink className="btn btn-info ml-1" to="/agreements">Shartnomalar</NavLink></li>
									<li><NavLink className="btn btn-info ml-1" to="/groups?type=paid">Guruhlar</NavLink></li>
									<li><NavLink className="btn btn-info ml-1" to="/listeners?type=paid">Tinglovchilar</NavLink></li>
								</div>
							</div>
						</div>
						}
						{hasRole(this.props.user, 'ADMIN') &&
							<div>
								<li><NavLink className="btn btn-info ml-1" to="/tendencies?type=all">Yo`nalishlar</NavLink></li>
								<li><NavLink className="btn btn-info ml-1" to="/userList">Foydalanuvchilar</NavLink></li>
							</div>
						}
						<div className="col-md-2 text-right">
							<div>
								<button type="button" className="btn btn-info dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
									{this.props.user.lastName + ' ' + this.props.user.firstName}
								</button>
								<div className="dropdown-menu">
									<span className="dropdown-item linkStyle" onClick={this.props.openModal}><i className="fa fa-edit"/>&nbsp;Parolni o`zgartirish</span>
									<div className="dropdown-divider"/>
									<span className="dropdown-item linkStyle" onClick={this.logout.bind(this)}><i className="fa fa-external-link"/>&nbsp;Chiqish</span>
								</div>
							</div>
						</div>
					</div>
				</ul>
				}
				<Modal open={this.state.modalOpen} onClose={this.onCloseModal.bind(this)} center>
					<ChangeBHMView/>
				</Modal>
			</div>
		)
	}
}