import React, {Component} from 'react';
import {apiHost, logout, pageNotFound} from "./Utils";
import axios from 'axios';
import swal from 'sweetalert';
import 'react-responsive-modal/styles.css';
import Modal from 'react-responsive-modal';
import authHeader from "./services/auth-header";
import Swal from "sweetalert2";

export default class RevertedCertificateNumbersView extends Component {

    constructor(props) {
        super(props);
        this.state = {
            editModalOpen: false,
            tableRows: [],
            selectedRow: {},
            groups: [],
            listeners: [],
        }
    }

    componentDidMount() {
        this.loadData();
        this.getGroups();
    }

    loadData() {
        axios.get(apiHost + '/api/admin/revertedCertificate/list', authHeader())
            .then(result => {
            if (result.status === 200) {
                if (result.data !== null) {
                    let rows = [];
                    rows = result.data.map((row, index) => {
                        return <tr>
                            <td>{index + 1}</td>
                            <td>{row.code}</td>
                            <td>{row.name}</td>
                            <td className="text-center">
                                <button className="btn btn-success" onClick={this.openModal.bind(this, row, 'editModalOpen')}><i className="fa fa-credit-card"/></button>
                                <button className="btn btn-danger ml-1" onClick={this.removeCertificate.bind(this, row)}><i className="fa fa-remove"/></button>
                            </td>
                        </tr>
                    });
                    this.setState({
                        tableRows: rows
                    })
                }
            }
        }).catch(error=>{
            if (error !== undefined && error !== null && error.response.status === 401) {
                logout();
            }
        });
    }

    getGroups() {
        axios.get(apiHost + '/api/course/list?type=all', authHeader()).then(result => {
            if (result.status === 200) {
                let rows = result.data.map(row=> {
                    return <option key={'gr_' + row.id} value={row.id}>{row.name}</option>;
                });
                this.setState({
                    groups: rows
                })
            }
        }).catch(error=>{
            if (error !== undefined && error !== null && error.response.status === 401) {
                logout();
            }
        })
    }

    getListeners(e) {
        let groupId = e.target.value;
        axios.get(apiHost + '/api/listener/withoutCertificate?groupId=' + groupId, authHeader()).then(result => {
            if (result.status === 200) {
                let rows = result.data.map(row=> {
                    return <option key={'gr_' + row.id} value={row.id}>{row.name}</option>;
                });
                this.setState({
                    listeners: rows
                })
            }
        }).catch(error=>{
            if (error !== undefined && error !== null && error.response.status === 401) {
                logout();
            }
        })
    }

    openModal(row, modalName) {
        this.setState({
            selectedRow: row,
            [modalName]: true
        })
    }

    closeModal(modalName) {
        this.setState({
            selectedRow: {},
            [modalName]: false
        })
    }

    removeCertificate(row) {
        Swal.fire({
            title: 'Siz haqiqatdan ham ' + row.code + row.name + ' sertifikat raqamini o`chirmoqchimisiz?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'O`chirish',
            cancelButtonText: 'Bekor qilish'
        }).then((result1) => {
            if (result1.value) {
                axios.delete(apiHost + '/api/admin/revertedCertificate/remove?id=' + row.id, authHeader())
                    .then(result => {
                        if (result.status === 200) {
                            Swal.fire(
                                'O`chirildi!',
                                result.data.message,
                                'success'
                            );
                            this.getData(null, null, this.state.start, this.state.offset);
                        } else {
                            Swal.fire(
                                'Xatolik',
                                result.data.message,
                                'error'
                            )
                        }
                    }).catch(error=>{
                    if (error !== undefined && error !== null && error.response.status === 401) {
                        logout();
                    }
                });
            } else if (result1.dismiss === Swal.DismissReason.cancel) {
                Swal.fire(
                    'Bekor qilindi',
                    '',
                    'error'
                )
            }
        });
    }

    generateCertificate(e) {
        let listenerId = document.getElementById('listener').value;
        axios.post(apiHost + '/api/listener/generateCertificate2?listenerId=' + listenerId + '&certificateId=' + this.state.selectedRow.id,
            null, authHeader()).then(result => {
            if (result.status === 200) {
                this.loadData();
                swal(result.data.message, "", "success");
                this.onCloseModal();
            } else if (result.status === 500) {
                swal(result.data.message, "", "error");
            }
        }).catch(error=>{
            if (error !== undefined && error !== null && error.response.status === 401) {
                logout();
            }
        })
    }

    render() {
        pageNotFound();
        return (
            <div className="content">
                <div className="container card card-body mt-1 shadow-sm">
                    <div className="form-row">
                        <div className="col-md-12">
                            <table className="table table-striped table-bordered shadow" style={{tableLayout: "fixed"}}>
                                <thead>
                                <tr>
                                    <th style={{width: "5%"}}>№</th>
                                    <th>Prefiks</th>
                                    <th>Sertifikat raqami</th>
                                    <th width="8%">Amal</th>
                                </tr>
                                </thead>
                                <tbody>
                                {this.state.tableRows}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <Modal open={this.state.editModalOpen} onClose={this.closeModal.bind(this, 'editModalOpen')} center>
                    <div style={{padding: "20px", minWidth: "200px"}}>
                        <div className="form-row">
                            <div className="col-md-12">
                                <label htmlFor="groups">Guruh</label>
                                <select className="form-control" id="groups" onChange={this.getListeners.bind(this)}>
                                    {this.state.groups}
                                </select>
                            </div>
                        </div>
                        <div className="form-row mt-2">
                            <div className="col-md-12">
                                <label htmlFor="listener">Tinglovchi</label>
                                <select className="form-control" id="listener">
                                    {this.state.listeners}
                                </select>
                            </div>
                        </div>
                        <div className="form-row mt-2">
                            <div className="col-md-12 text-center">
                                <button className="btn btn-success" onClick={this.generateCertificate.bind(this)}>Saqlash</button>
                            </div>
                        </div>
                    </div>
                </Modal>
            </div>
        )
    }
}