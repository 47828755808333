export default function authHeader() {
  const user = JSON.parse(localStorage.getItem('user'));

  if (user && user.accessToken) {
    return {
      headers: {
        'Authorization': 'Bearer ' + user.accessToken,
        // 'Access-Control-Allow-Origin': 'http://localhost:3000',
        // 'Access-Control-Allow-Origin': 'http://elektron.uzmarkaz.uz',
        'Access-Control-Allow-Headers': '*',
        'Access-Control-Allow-Credentials': 'true',
        'Access-Control-Allow-Methods' : 'GET, POST, PUT, PATCH, DELETE'
      }
    };
  } else {
    return {};
  }
}

export function blobAuthHeader() {
  const user = JSON.parse(localStorage.getItem('user'));

  if (user && user.accessToken) {
    return {
      headers: {
        'Authorization': 'Bearer ' + user.accessToken,
        // 'Access-Control-Allow-Origin': 'http://localhost:3000',
        // 'Access-Control-Allow-Origin': 'http://elektron.uzmarkaz.uz',
        'Access-Control-Allow-Headers': '*',
        'Access-Control-Allow-Credentials': 'true',
        'Access-Control-Allow-Methods' : 'GET, POST, PUT, PATCH, DELETE'
      },
      'responseType': 'blob'
    };
  } else {
    return {};
  }
}