import React, {Component} from 'react';
import * as _ from './Utils';
import axios from 'axios';
import swal from "sweetalert";
import {apiHost, logout, pageNotFound, validateOnlyNumberField} from "./Utils";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {registerLocale, setDefaultLocale} from "react-datepicker";
import uz from 'date-fns/locale/uz';
import getTime from 'date-fns/getTime';
import authHeader from "./services/auth-header";

registerLocale('uz', uz);
setDefaultLocale('uz');

class AddListenerView extends Component {

    constructor(props) {
        super(props);
        let listenerId = localStorage.getItem('listenerId');
        let urlParams = new URLSearchParams(window.location.search);
        let listenerType = urlParams.get('type');
        this.state = {
            listenerType: listenerType,
            lastName: '',
            firstName: '',
            middleName: '',
            passportNumber: '',
            position: '',
            listenerId: listenerId,
            groupNumber: 0,
            pinflNumber: '',
            agreement: 0,
            examinationDate: new Date(),
            startDate: new Date(),
            endDate: new Date(),
            excludeDate: new Date(),
            registerNumber: '',
            phoneNumber: '',
            solution: '',
            groups: [],
            agreements: [],
            selectedFiles: []
        };
        this.setValue = this.setValue.bind(this);
        this.convertFiles = this.convertFiles.bind(this);
    }

    componentDidMount() {
        axios.get(apiHost + '/api/agreement/list2', authHeader())
            .then(result => {
                if (result.status === 200) {
                    let items = result.data.map(row => {
                        return <option key={'al_' + row.id} value={row.id}>{row.name}</option>
                    });
                    items.unshift(<option key={'al_0'} value={0}>Tanlang</option>);
                    this.setState({
                        agreements: items
                    })
                }
            }).catch(error=>{
            if (error !== undefined && error !== null && error.response.status === 401) {
                logout();
            }
        });
        axios.get(apiHost + '/api/course/list?type=' + this.state.listenerType, authHeader())
            .then(result => {
                if (result.status === 200) {
                    let items = result.data.map(row => {
                        return <option key={'gl_' + row.id} value={row.id}>{row.name}</option>
                    });
                    items.unshift(<option key={'gl_0'} value={0}>Tanlang</option>);
                    this.setState({
                        groups: items
                    })
                }
            }).catch(error=>{
            if (error !== undefined && error !== null && error.response !== undefined && error.response !== null && error.response.status === 401) {
                logout();
            }
        });

        if (this.state.listenerId !== undefined && this.state.listenerId !== null) {
            axios.get(apiHost + '/api/listener/item?id=' + this.state.listenerId, authHeader())
                .then(result => {
                    if (result.status === 200) {
                        let item = result.data;
                        this.setState({
                            lastName: item.lastName,
                            firstName: item.firstName,
                            middleName: item.middleName,
                            passportNumber: item.passportNumber,
                            pinflNumber: item.pinflNumber,
                            position: item.position,
                            groupNumber: item.group !== null ? item.group.id : 0,
                            agreement: item.agreement,
                            solution: item.solution !== undefined && item.solution !== null ? item.solution.id : '',
                            examinationDate: item.examinationDate,
                            startDate: item.startDate,
                            endDate: item.endDate,
                            excludeDate: item.excludeDate,
                            phoneNumber: item.phoneNumber,
                            registerNumber: item.registerNumber
                        })
                    }
                }).catch(error=>{
                if (error !== undefined && error !== null && error.response.status === 401) {
                    logout();
                }
            })
        }
    }

    setValue(e) {
        let name = e.target.id;
        let value = e.target.value;
        if (name === 'pinflNumber') {
            validateOnlyNumberField(e);
        }
        this.setState({
            [name]: value,
        })
    }

    setExamDate(examinationDate) {
        this.setState({
            examinationDate: examinationDate
        })
    }

    setStartDate(startDate) {
        this.setState({
            startDate: startDate
        })
    }

    setEndDate(endDate) {
        this.setState({
            endDate: endDate
        })
    }

    setExcludeDate(excludeDate) {
        this.setState({
            excludeDate: excludeDate
        })
    }

    convertFiles(e) {
        let files = [];
        for (let i = 0; i < e.target.files.length; i++) {
            let file = e.target.files[i];
            if (file.type === 'image/jpg' || file.type === 'image/jpeg' || file.type === 'image/png') {
                this.getBase64(file).then(result => {
                    files.push(result);
                    this.setState({
                        selectedFiles: files
                    });
                })
            } else {
                swal('Barcha tinglovchilarning pasportini rasm faylini yuklang. (JPG, JPEG, PNG)', '', 'error');
                return;
            }
        }
    }

    getBase64 = file => {
        return new Promise(resolve => {
            let baseURL = "";
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                baseURL = reader.result;
                resolve(baseURL);
            };
        });
    };

    save() {
        let isEdit = this.state.listenerId !== undefined && this.state.listenerId !== null;
        let groupNumber = document.getElementById('groupNumber').value;
        if (isEdit && Number(groupNumber) === 0) {
            swal('Guruhni tanlang', '', 'error');
            return;
        }
        let data = {
            'lastName': this.state.lastName,
            'firstName': this.state.firstName,
            'middleName': this.state.middleName,
            'passportNumber': this.state.passportNumber,
            'position': this.state.position,
            'group': {
                'id': Number(groupNumber)
            },
            'phoneNumber': this.state.phoneNumber,
            'pinflNumber': this.state.pinflNumber
        }
        if (isEdit) {
            data['id'] = this.state.listenerId;
        }
        if (this.state.examinationDate !== undefined && this.state.examinationDate !== null) {
            data['examinationDate'] = getTime(this.state.examinationDate);
        }
        if (this.state.startDate !== undefined && this.state.startDate !== null) {
            data['startDate'] = getTime(this.state.startDate);
        }
        if (this.state.endDate !== undefined && this.state.endDate !== null) {
            data['endDate'] = getTime(this.state.endDate);
        }
        if (!isEdit) {
            data['passportFileBase64'] = this.state.selectedFiles[0];
        }
        axios.post(apiHost + '/api/listener/' + (isEdit ? 'edit' : 'add'), data, authHeader())
            .then(result => {
                if (result.status === 200) {
                    swal("Ma'lumot saqlandi", "", 'success');
                } else {
                    swal("Ma'lumot saqlashda xatolik", "", 'error');
                }
            }).catch(error=>{
            if (error !== undefined && error !== null && error.response.status === 401) {
                logout();
            }
        })
    }

    saveLastResult() {
        axios.post(_.apiHost + '/api/listener/saveListenerResult',
            {
                "id": this.state.listenerId,
                "registerNumber": this.state.registerNumber,
                "solution": {
                    "id": document.getElementById('solution').value
                }
            },
            authHeader()).then(result => {
            if (result.status === 200) {
                swal("Ma'lumot saqlandi", "", 'success');
            } else {
                swal("Ma'lumot saqlashda xatolik", "", 'error');
            }
        }).catch(error=>{
            if (error !== undefined && error !== null && error.response.status === 401) {
                logout();
            }
        })
    }

	saveExcludeResult() {
		let data = {
            "id": Number(this.state.listenerId),
		    "excludeDate": getTime(this.state.excludeDate)
        };
		axios.post(_.apiHost + '/api/listener/saveExcludeDate', data, authHeader()).then(result => {
			if (result.status === 200) {
				swal(result.data.message, "", 'success');
			} else {
				swal(result.data.message, "", 'error');
			}
		}).catch(error=>{
            if (error !== undefined && error !== null && error.response.status === 401) {
                logout();
            }
        })
	}

    render() {
        pageNotFound();
        let user = JSON.parse(localStorage.getItem('user'));
        return (
            <div className="container card card-body my-4">
                <div className="form-row">
                    <div className="col-md-12 text-center">
                        <label><h4>Tinglovchi ma'lumotlarini kiritish <span style={{color: "red"}}>(pasport ma'lumotlari asosida)</span>
                        </h4></label>
                    </div>
                </div>
                <div className="form-row">
                    <div className="col-md-4">
                        <label htmlFor="lastName">Familiyasi</label>
                        <input type="text" id="lastName" className="form-control" defaultValue={this.state.lastName}
                               onChange={this.setValue}/>
                    </div>
                    <div className="col-md-4">
                        <label htmlFor="firstName">Ismi</label>
                        <input type="text" id="firstName" className="form-control" defaultValue={this.state.firstName}
                               onChange={this.setValue}/>
                    </div>
                    <div className="col-md-4">
                        <label htmlFor="middleName">Sharifi</label>
                        <input type="text" id="middleName" className="form-control" defaultValue={this.state.middleName}
                               onChange={this.setValue}/>
                    </div>
                </div>
                <div className="form-row">
                    <div className="col-md-3">
                        <label htmlFor="passportNumber">Pasport raqami</label>
                        <input id="passportNumber" className="form-control" defaultValue={this.state.passportNumber}
                               onChange={this.setValue}/>
                    </div>
                    <div className="col-md-6">
                        <label htmlFor="position">Lavozimi</label>
                        <input id="position" className="form-control" defaultValue={this.state.position || ''}
                               onChange={this.setValue}/>
                    </div>
                    <div className="col-md-3">
                        <label htmlFor="groupNumber">Guruhi</label>
                        <select id="groupNumber" className="form-control" value={this.state.groupNumber}
                                onChange={this.setValue}>
                            {this.state.groups}
                        </select>
                    </div>
                </div>
                <div className="form-row">
                    <div className="col-md-3">
                        <label htmlFor="pinflNumber">JShShIR raqami</label>
                        <input id="pinflNumber" maxLength="14" className="form-control" defaultValue={this.state.pinflNumber || ''}
                               onChange={this.setValue}/>
                    </div>
                    <div className="col-md-3">
                        <label htmlFor="startDate">Kurs boshlangan kun</label>
                        <DatePicker className="form-control" id="startDate" locale="uz" dateFormat="dd.MM.yyyy"
                                    selected={this.state.startDate} onChange={this.setStartDate.bind(this)}/>
                    </div>
                    <div className="col-md-3">
                        <label htmlFor="endDate">Kurs yakunlangan kun</label>
                        <DatePicker className="form-control" id="endDate" locale="uz" dateFormat="dd.MM.yyyy"
                                    selected={this.state.endDate} onChange={this.setEndDate.bind(this)}/>
                    </div>
                    <div className="col-md-3">
                        <label htmlFor="examinationDate">Imtihon kuni</label>
                        <DatePicker className="form-control" id="examinationDate" locale="uz" dateFormat="dd.MM.yyyy"
                                    selected={this.state.examinationDate} onChange={this.setExamDate.bind(this)}/>
                    </div>
                </div>
                <div className="form-row">
                    <div className="col-md-4">
                        <label htmlFor="position">Telefon raqami</label>
                        <input id="phoneNumber" className="form-control" defaultValue={this.state.phoneNumber}
                               onChange={this.setValue}/>
                    </div>
                    <div className="col-md-4">
                        <label htmlFor="passportFile">Pasportini yuklang</label>
                        <input id="passportFile" type="file" className="form-control" onChange={this.convertFiles}/>
                    </div>
                </div>
                <div className="form-row mt-3">
                    <div className="col-md-12 text-center">
                        <button className="btn btn-success" onClick={this.save.bind(this)}>Saqlash</button>
                    </div>
                </div>

                {(user.roles[0] === 'CONTROLLER' || user.roles[0] === 'MAIN_CONTROLLER') &&
                <>
                    <fieldset>
                        <legend>Imtihon natijasini kiritish</legend>
                        <div className="form-row">
                            <div className="col-md-4">
                                <label htmlFor="solution">Yakuniy xulosasi</label>
                                <select id="solution" className="form-control" defaultValue={this.state.solution} onChange={this.setValue}>
                                    <option value={0}>Tanlang</option>
                                    <option value={1}>O`tdi</option>
                                    <option value={2}>O`tmadi</option>
                                    <option value={3}>Uzrli sabablarga ko`ra kelmadi</option>
                                    <option value={4}>Uzrsiz sabablarga ko`ra kelmadi
                                    </option>
                                </select>
                            </div>
                            <div className="col-md-4">
                                <label htmlFor="registerNumber">Qayd raqami</label>
                                <input id="registerNumber" className="form-control" defaultValue={this.state.registerNumber}
                                       onChange={this.setValue}/>
                            </div>
                            <div className="col-md-4">
                                <button className="btn btn-success bottomAlign" onClick={this.saveLastResult.bind(this)}>Saqlash</button>
                            </div>
                        </div>
                    </fieldset>
                    <fieldset>
                        <legend>Tinglovchilar safidan chiqarish</legend>
                        <div className="form-row">
                            <div className="col-md-6">
                                <label htmlFor="excludeDate">Sanasi &nbsp;</label>
                                <DatePicker className="form-control" id="excludeDate" locale="uz"
                                            dateFormat="dd.MM.yyyy"
                                            selected={this.state.excludeDate}
                                            onChange={this.setExcludeDate.bind(this)}/>
                            </div>
                            <div className="col-md-6">
                                <button className="btn btn-success"
                                        onClick={this.saveExcludeResult.bind(this)}>Saqlash
                                </button>
                            </div>
                        </div>
                    </fieldset>
                </>
                }
            </div>
        )
    }
}

export default AddListenerView;