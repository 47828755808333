import React from "react";
import ListenerHomeView from "./ListenerHomeView";
import LoginPage from "./LoginPage";
import AddAgreementView from "./AddAgreementView";
import {Route, Routes} from "react-router-dom";
import AgreementView from "./AgreementView";
import AccountantHomeView from "./AccountantHomeView";
import ControllerHomeView from "./ControllerHomeView";
import AddListenerView from "./AddListenerView";
import ReportsView from "./ReportsView";
import AgreementList from "./AgreementList";
import ListenersListView from "./ListenersListView";
import MainMenu from "./MainMenu";
import ListenerView from "./ListenerView";
import AddGroupView from "./AddGroupView";
import GroupListView from "./GroupListView";
import GroupView from "./GroupView";
import HelpView from "./HelpView";
import AdminHomeView from "./AdminHomeView";
import AnswerToQuestionView from "./AnswerToQuestionView";
import AddTendencyView from "./AddTendencyView";
import TendencyList from "./TendencyList";
import TendencyView from "./TendencyView";
import MarketingHomeView from "./MarketingHomeView";
import AddTendency2View from "./AddTendency2View";
import 'react-responsive-modal/styles.css';
import Modal from 'react-responsive-modal';
import {apiHost} from "./Utils";
import axios from 'axios';
import swal from 'sweetalert';
import EditAgreementView from "./EditAgreementView";
import EditRequisitesView from "./EditRequisitesView";
import EditAgreementPriceView from "./EditAgreementPriceView";
import PaidTendencyList from "./PaidTendencyList";
import UserList from "./UserList";
import AgreementEditView from "./AgreementEditView";
import AccountantReportsView from "./AccountantReportsView";
import RevertedCertificateNumbersView from "./RevertedCertificateNumbersView";
import LatePaymentsListView from "./LatePaymentsListView";
import PayedAndUnGroupedListenersList from "./PayedAndUnGroupedListenersList";
import DeletedObjectsList from "./DeletedObjectsList";
import CertificateReportView from "./CertificateReportView";
import PdfPageSettingView from "./PdfPageSettingView";
import MainControllerHomeView from "./MainControllerHomeView";
import OldListenersListView from "./OldListenersListView";
import OldAgreementsListView from "./OldAgreementsListView";
import AddOldAgreementView from "./AddOldAgreementView";
import OldAgreementView from "./OldAgreementView";
import SignAgreementView from "./SignAgreementView";
import DuplicateCertificateListView from "./DuplicateCertificateListView";
import authHeader from "./services/auth-header";

export default class App extends React.Component {

	constructor() {
		super();
		let user = localStorage.getItem('user');
		if (user !== undefined && user !== null) {
			user = JSON.parse(user);
		}
		this.state = {
			user: user,
			showHelp: true,
			openModal: false,
			openHelpModal: false
		};
	}

	setUser(user) {
		this.setState({
			user: user
		});
	}

	changePassword() {
		let user = this.state.user;
		let data = {};
		data.id = user.userId;
		data.oldPassword = document.getElementById('oldPassword').value;
		data.password = document.getElementById('newPassword').value;
		axios.post(apiHost + '/api/user/changePassword', data, authHeader())
			.then(result=>{
				if (result.status === 200) {
					swal(result.data.message, '', 'success');
				} else {
					swal(result.data.message, '', 'error');
				}
			}).catch(error=>{
			if (error !== undefined && error !== null) {
				swal(error.response.data.message, '', 'error');
			}
		})
	}

	openModal(modalName) {
		this.setState({
			[modalName]: true
		})
	}

	onCloseModal(modalName) {
		this.setState({
			[modalName]: false
		})
	}

	render() {
		let search = window.location.href;
		search = search.split("/");
		let showHelp = search.length === 4 && search[3] === '';
		return (
			<div>
				<div id="app">
					<MainMenu user={this.state.user} openModal={this.openModal.bind(this, 'openModal')}/>
					<div className="content">
						<div className="col-md-12">
							<Routes>
								<Route path="/" element={<ListenerHomeView/>}/>
								<Route path="/login" element={<LoginPage setUser={this.setUser.bind(this)}/>}/>
								<Route path="/agreements" element={<AgreementList/>}/>
								<Route path="/addAgreement" element={<AddAgreementView/>}/>
								<Route path="/agreementView" element={<AgreementView/>}/>
								<Route path="/agreementEdit" element={<AgreementEditView/>}/>
								<Route path="/accountantReports" element={<AccountantReportsView/>}/>
								<Route path="/groups" element={<GroupListView/>}/>
								<Route path="/addGroup" element={<AddGroupView/>}/>
								<Route path="/groupView" element={<GroupView/>}/>
								<Route path="/accountant" element={<AccountantHomeView/>}/>
								<Route path="/control" element={<ControllerHomeView/>}/>
								<Route path="/maincontrol" element={<MainControllerHomeView/>}/>
								<Route path="/listeners" element={<ListenersListView/>}/>
								<Route path="/addListener" element={<AddListenerView/>}/>
								<Route path="/listenerView" element={<ListenerView/>}/>
								<Route path="/reports" element={<ReportsView/>}/>
								<Route path="/help" element={<HelpView/>}/>
								<Route path="/admin" element={<AdminHomeView/>}/>
								<Route path="/userList" element={<UserList/>}/>
								<Route path="/addAnswer" element={<AnswerToQuestionView/>}/>
								<Route path="/addTendency" element={<AddTendencyView/>}/>
								<Route path="/addTendency2" element={<AddTendency2View/>}/>
								<Route path="/tendencies" element={<TendencyList/>}/>
								<Route path="/tendencyView" element={<TendencyView/>}/>
								<Route path="/marketing" element={<MarketingHomeView/>}/>
								<Route path="/paidTendencies" element={<PaidTendencyList/>}/>
								<Route path="/editAgreementPrice" element={<EditAgreementPriceView/>}/>
								<Route path="/editAgreement" element={<EditAgreementView/>}/>
								<Route path="/editRequisites" element={<EditRequisitesView/>}/>
								<Route path="/revertedCertificates" element={<RevertedCertificateNumbersView/>}/>
								<Route path="/duplicateCertificates" element={<DuplicateCertificateListView/>}/>
								<Route path="/latePayments" element={<LatePaymentsListView/>} />
								<Route path="/newPayments" element={<PayedAndUnGroupedListenersList/>}/>
								<Route path="/deletedObjects" element={<DeletedObjectsList/>}/>
								<Route path="/creport" element={<CertificateReportView/>}/>
								<Route path="/settings" element={<PdfPageSettingView/>}/>
								<Route path="/agreements2" element={<OldAgreementsListView/>}/>
								<Route path="/listeners2" element={<OldListenersListView/>}/>
								<Route path="/addAgreement2" element={<AddOldAgreementView/>}/>
								<Route path="/oaView" element={<OldAgreementView/>}/>
								<Route path="/saView" element={<SignAgreementView/>} />
							</Routes>
						</div>
					</div>
				</div>
				<Modal open={this.state.openModal} onClose={this.onCloseModal.bind(this, 'openModal')} center>
					<div style={{paddingTop: "40px"}}>
						<div className="form-row">
							<div className="col-md-12 text-center"><h5>Parolni o`zgartirish</h5></div>
						</div>
						<div className="form-row">
							<div className="col-md-12">
								<label htmlFor="oldPassword">Avvalgi parol</label>
								<input id="oldPassword" type="password" className="form-control"/>
							</div>
						</div>
						<div className="form-row">
							<div className="col-md-12">
								<label htmlFor="oldPassword">Yangi parol</label>
								<input id="newPassword" type="password" className="form-control"/>
							</div>
						</div>
						<div className="form-row mt-3">
							<div className="col-md-12 text-center">
								<button className="btn btn-outline-info" onClick={this.changePassword.bind(this)}>Saqlash</button>
							</div>
						</div>
					</div>
				</Modal>
				{showHelp &&
				<div className="form-row">
					<div className="col-md-12 text-right">
						<div onClick={this.openModal.bind(this, 'openHelpModal')}>
							<img src="images/help.png" alt="Yordam kerakmi?" className="help"/>
						</div>
					</div>
				</div>
				}
				<Modal open={this.state.openHelpModal} onClose={this.onCloseModal.bind(this, 'openHelpModal')} center>
					<div style={{paddingTop: "50px"}}>
						<div className="form-row">
							<div className="col-md-12 text-center">
								<h5>Qo`shimcha ma'lumotlar uchun:</h5>
								<br/>
								<h4>+998 71 200-02-35</h4>
							</div>
						</div>

					</div>
				</Modal>
			</div>
		)
	}
}
